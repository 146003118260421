import * as React from 'react';
import { FunctionComponent } from 'react';
import * as classNames from 'classnames';

interface Props {
  isOpen: boolean;
  title?: string;
  body?: string;
  buttonText?: string;
  buttonCancelText?: string;
  onButtonClick?: () => void;
  onCloseClick?: () => void;
  customClass?: string;
  onCloseIconClick?: () => void;
  showFooter?: boolean;
}

export const SimpleModal: FunctionComponent<Props> = props => {
  const {
    isOpen,
    title,
    onCloseClick,
    body,
    children,
    buttonText,
    onButtonClick,
    buttonCancelText,
    customClass,
    onCloseIconClick,
    showFooter = true
  } = props;

  if (!isOpen) {
    return null;
  }

  const modalClasses = classNames({
    mDisplayBlock: isOpen,
    show: isOpen,
    modal: true,
    fade: true
  });

  const isTitleExist = typeof title !== 'undefined';
  const isOnCloseClickFunction = typeof onCloseClick === 'function';
  const isOnCloseIconClickFunction = typeof onCloseIconClick === 'function';
  const isHeaderRender = isTitleExist || isOnCloseClickFunction;
  const isBodyExist = typeof body !== 'undefined';
  const isChildrenElementsExist = typeof children !== 'undefined';
  const isBodyRender = isBodyExist || isChildrenElementsExist;
  const isButtonTextExist = typeof buttonText !== 'undefined';
  const isOnButtonClickFunction = typeof onButtonClick === 'function';
  const isButtonRender = isButtonTextExist && isOnButtonClickFunction;
  const isFooterRender = showFooter && (isButtonRender || isOnCloseClickFunction);

  const isCloseIconRender = isOnCloseIconClickFunction || isOnCloseClickFunction;

  const customClasses = classNames('modal-dialog modal-dialog-scrollable', customClass);

  return (
    <div className={modalClasses}>
      <div className={customClasses}>
        <div className="modal-content">
          {isHeaderRender && (
            <div className="modal-header">
              {isTitleExist && <h5 className="modal-title">{title}</h5>}
              {isCloseIconRender && (
                <button
                  type="button"
                  className="close"
                  onClick={isOnCloseIconClickFunction ? onCloseIconClick : onCloseClick}
                >
                  <span>&times;</span>
                </button>
              )}
            </div>
          )}
          {isBodyRender && (
            <div className="modal-body mPreWrap">
              {isBodyExist && <div className="font-weight-bold">{body}</div>}
              {isChildrenElementsExist && children}
            </div>
          )}
          {isFooterRender && (
            <div className="modal-footer">
              {isOnCloseClickFunction && (
                <button type="button" className="btn btn-secondary" onClick={props.onCloseClick}>
                  {buttonCancelText}
                </button>
              )}
              {isButtonRender && (
                <button type="button" className="btn btn-primary" onClick={props.onButtonClick}>
                  {buttonText}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SimpleModal.displayName = 'SimpleModal';
SimpleModal.defaultProps = {
  buttonCancelText: 'Cancel'
};
